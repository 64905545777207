import styled from 'styled-components';

import logo from '../assets/img/logo-white.png';

const HeaderContainer = styled.header`
  padding: 24px 48px;
  position: absolute;
`;

const HeaderLogo = styled.img`
  height: 36px;
`;

const Header = () => (
  <HeaderContainer>
    <HeaderLogo src={logo} />
  </HeaderContainer>
);

export default Header;
