import { useEffect, useContext } from 'react';

import UserContext from '../../context/user/UserContext';
import { PrimaryLarge, TransparentLarge } from '../buttons';
import { Warning } from '../icons';
import { Flex, HSeparator } from '../ui';
import { NetworkPill } from '../texts';
import { chooseRequiredNetwork, requiredNetwork } from '../../services/web3';
import Analytics from '../../services/analytics';

import { ModalTitle, ModalText } from './styles';

const ModalNetwork = ({ data, resetModal }) => {
  const { name } = data;
  const { name: requiredNetworkName } = requiredNetwork;

  const userContextData = useContext(UserContext);

  useEffect(() => {
    Analytics.errorMessage({ message: 'Change your network', ...userContextData });
  }, []);

  const handleChangeNetwork = () => {
    chooseRequiredNetwork();
    Analytics.changeNetwork(userContextData);
  };

  const handleClosePopup = () => {
    resetModal();
    Analytics.closePopup({ popup: 'network', ...userContextData });
  };

  return (
    <Flex direction="column" justify="center">
      <Warning />
      <HSeparator height="8px" />
      <ModalTitle>Change your network</ModalTitle>
      <HSeparator height="8px" />
      <ModalText>
        To claim Kasta you need to be on
        {' '}
        {requiredNetworkName}
        {' '}
        network.
      </ModalText>
      <HSeparator height="8px" />
      <ModalText>You’re currently on:</ModalText>
      <NetworkPill>{name}</NetworkPill>
      <HSeparator height="16px" />
      <ModalText>Please change to:</ModalText>
      <NetworkPill>{requiredNetworkName}</NetworkPill>
      <HSeparator height="24px" />
      <PrimaryLarge size="medium" width="100%" onClick={handleChangeNetwork}>Change</PrimaryLarge>
      <HSeparator height="10px" />
      <TransparentLarge
        size="medium"
        width="100%"
        onClick={handleClosePopup}
      >
        Close
      </TransparentLarge>
    </Flex>
  );
};

export default ModalNetwork;
