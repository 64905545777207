import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';

import theme from './theme';
import GlobalFonts from './assets/fonts';
import GlobalStyles from './styles/global';
import Home from './pages/Home';
import ModalProvider from './context/modal/ModalProvider';
import UserProvider from './context/user/UserProvider';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <GlobalFonts />
      <GlobalStyles />
      <UserProvider>
        <ModalProvider>
          <Home />
        </ModalProvider>
      </UserProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
