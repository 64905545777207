import {
  useState, useEffect, useContext, useRef,
} from 'react';
import styled from 'styled-components';

import homeHero from '../assets/img/home-hero.jpg';
import Layout from '../components/Layout';
import {
  HSeparator, Flex, // FullWidthImg, ResponsiveContent,
} from '../components/ui';
import HomeTables from '../components/HomeTables';
import HomeConnect from '../components/HomeConnect';
import ClaimTable from '../components/ClaimTable';
import ClaimHistory from '../components/ClaimHistory';
import { H1 } from '../components/texts';
// import TokenDistribution from '../components/charts/TokenDistribution';
import {
  userConnect, getUserAccount, setupEventHandlers, getNetwork,
} from '../services/web3';
import Analytics from '../services/analytics';
import { ALLOC_PURPOSES } from '../config';
import { device } from '../styles/responsiveness';
import ModalContext from '../context/modal/ModalContext';
import UserContext from '../context/user/UserContext';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  margin: auto;
  padding: 3%;

  @media ${device.tablet} {
    max-width: 672px;
    padding: 3% 0;
  }
  @media ${device.laptop} {
    max-width: 1032px;
  }
`;

const HomeHero = styled.div`
  height: ${({ hasAccount }) => (hasAccount ? '1156px' : '90vh')};
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 80%;

  @media ${device.tablet} {
    height: ${({ hasAccount }) => (hasAccount ? '1024px' : '90vh')};
    background-position-x: center;
    background-position-y: center;
  }
  @media ${device.laptop} {
    height: ${({ hasAccount }) => (hasAccount ? '940px' : '90vh')};
    background-position-x: center;
    background-position-y: center;
  }
`;

const HomeHeroWrapper = styled(Flex)`
  height: 100%;
  max-width: 600px;
  margin: auto;
  padding: 100px 5%;
  align-items: stretch;

  @media ${device.tablet} {
    max-width: 672px;
    padding: 100px 0;
    align-items: flex-start;
  }
  @media ${device.laptop} {
    max-width: 1032px;
    align-items: flex-start;
  }
`;

const Home = () => {
  const [loadingMetamaskConnection, setLoadingMetamaskConnection] = useState(false);
  const [loadingUserAccount, setLoadingUserAccount] = useState(true);
  const currentNetworkRef = useRef(null);

  const {
    setModalData, setModalType, setShowModal, resetModal,
  } = useContext(ModalContext);

  const userContextData = useContext(UserContext);
  const {
    account, setAccount, resetUserInfo, setNetwork,
  } = userContextData;

  useEffect(() => {
    fetchUserAccount();
    fetchUserNetwork();
    setupEventHandlers({
      onNetworkChanged: fetchUserNetwork,
      onAccountsChanged: handleAccountChange,
    });
    console.log('TOKEN PORTAL VERSION: 2');
  }, []);

  const fetchUserNetwork = async () => {
    const network = await getNetwork();
    setNetwork(network);

    if (!network.isCorrectNetwork) {
      setModalData(network);
      setModalType('network');
      setShowModal(true);
    } else {
      if (currentNetworkRef.current && !currentNetworkRef.current.isCorrectNetwork) {
        Analytics.switchNetworkSuccess(userContextData);
      }
      resetModal();
    }
    currentNetworkRef.current = network;
  };

  const fetchUserAccount = async () => {
    const currentUserAccount = await getUserAccount();
    if (currentUserAccount) {
      setAccount(currentUserAccount);
      Analytics.inspectWallet({ ...userContextData, account: currentUserAccount });
    }
    setLoadingUserAccount(false);
  };

  const handleWalletConnect = async () => {
    setLoadingMetamaskConnection(true);
    Analytics.connectWallet(userContextData);
    const address = await userConnect();
    Analytics.connectWalletSuccess({ ...userContextData, account: address });
    setAccount(address);
    setLoadingMetamaskConnection(false);
  };

  const handleWalletDisconnect = async () => {
    resetUserInfo();
    Analytics.disconnectWallet(userContextData);
  };

  const handleAccountChange = (accounts) => {
    setAccount((Array.isArray(accounts) && accounts.length > 0
      ? accounts[0]
      : ''
    ));
  };

  if (loadingUserAccount) return null;

  return (
    <Layout>
      <HomeHero hasAccount={account} style={{ backgroundImage: `url("${homeHero}")` }}>
        <HomeHeroWrapper direction="column" justify={account ? 'flex-start' : 'center'}>
          {account
            ? (
              <ClaimTable
                userAccount={account}
                onWalletDisconnect={handleWalletDisconnect}
              />
            )
            : <HomeConnect isLoading={loadingMetamaskConnection} onWalletConnect={handleWalletConnect} />}
        </HomeHeroWrapper>
      </HomeHero>
      <Wrapper>
        <HSeparator height="10px" />
        <ClaimHistory />
        <HSeparator height="40px" />
        <H1 align={['left', 'center', 'left']}>
          Token
          {' '}
          <span className="withStroke">Portal</span>
        </H1>
        {/* <HSeparator height="40px" /> */}
        {/* <TokenDistribution /> */}
        {/* <HSeparator height={['40px', '80px', '80px']} /> */}
        {/* <H2>Live update</H2> */}
        {/* <HSeparator /> */}
        <HomeTables purposes={ALLOC_PURPOSES} />
        <HSeparator />
      </Wrapper>
    </Layout>
  );
};

export default Home;
