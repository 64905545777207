import styled from 'styled-components';

import BaseText from './BaseText';

const NetworkPill = styled(BaseText)`
  padding: 8px 16px;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.primary};
  border: 1px solid ${({ theme, light = false }) => (
    light ? theme.colors.greyDivider : theme.colors.primary
  )};
  border-radius: 24px;
  white-space: nowrap;
`;

export default NetworkPill;
