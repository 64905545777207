/* eslint-disable no-console */
/* eslint-disable no-new */
import Web3 from 'web3';

import tokenContractJson from '../contracts/KastaTokenV1.json';
import vestingContractJson from '../contracts/KastaVesting.json';
import vestingContractV3Json from '../contracts/KastaVestingV3.json';

const { abi } = tokenContractJson;
const { abi: vestingAbi } = vestingContractJson;
const { abi: vestingV3Abi } = vestingContractV3Json;
const TOKEN_CONTRACT_ADDRESS = process.env.REACT_APP_TOKEN_CONTRACT_ADDRESS;
const VESTING_CONTRACT_ADDRESS_PRIVATE = process.env.REACT_APP_VESTING_CONTRACT_ADDRESS_PRIVATE;
const VESTING_CONTRACT_ADDRESS_SEED = process.env.REACT_APP_VESTING_CONTRACT_ADDRESS_SEED;
const VESTING_CONTRACT_ADDRESS_ECOSYSTEM_REFERRALS = process.env.REACT_APP_VESTING_CONTRACT_ADDRESS_ECOSYSTEM_REFERRALS;
const VESTING_CONTRACT_ADDRESS_CORE_TEAM = process.env.REACT_APP_VESTING_CONTRACT_ADDRESS_CORE_TEAM;
const VESTING_CONTRACT_ADDRESS_OPERATIONS = process.env.REACT_APP_VESTING_CONTRACT_ADDRESS_OPERATIONS;
const REQUIRED_NETWORK = process.env.REACT_APP_REQUIRED_NETWORK;
const TOKEN_OWNER = process.env.REACT_APP_TOKEN_OWNER;
const INFURA_KEY = process.env.REACT_APP_INFURA_KEY;

const NETWORKS = {
  1: 'Ethereum mainnet',
  3: 'Ropsten',
  4: 'Rinkeby',
  42: 'Kovan',
  137: 'Polygon',
  80001: 'Polygon Testnet (Mumbai)',
};

const vestingContractAddresses = {
  seed: VESTING_CONTRACT_ADDRESS_SEED,
  private: VESTING_CONTRACT_ADDRESS_PRIVATE,
  ecosystem_referrals: VESTING_CONTRACT_ADDRESS_ECOSYSTEM_REFERRALS,
  core_team: VESTING_CONTRACT_ADDRESS_CORE_TEAM,
  operations: VESTING_CONTRACT_ADDRESS_OPERATIONS,
};

const vestingContractInterfaces = {
  seed: vestingAbi,
  private: vestingAbi,
  ecosystem_referrals: vestingV3Abi,
  core_team: vestingV3Abi,
  operations: vestingV3Abi,
};

function getNetworkById(id) {
  return NETWORKS[id] ? { id, name: NETWORKS[id] } : { id, name: 'unknow' };
}

function getWeb3() {
  // User has metamask or similar
  if (window.ethereum) return new Web3(window.ethereum);

  // Legacy dapp browsers, public wallet address always exposed
  if (window.web3) new Web3(window.web3.currentProvider);

  // Non-dapp browsers...
  const web3Provider = new Web3.providers.HttpProvider(`https://rinkeby.infura.io/v3/${INFURA_KEY}`);
  return new Web3(web3Provider);
}

function getTokenContract() {
  const web3 = getWeb3();
  return new web3.eth.Contract(abi, TOKEN_CONTRACT_ADDRESS);
}

function getVestingContractByType(type) {
  const web3 = getWeb3();
  const address = vestingContractAddresses[type];
  const contractInterface = vestingContractInterfaces[type];
  return new web3.eth.Contract(contractInterface, address);
}

async function getGasPrice() {
  const web3 = getWeb3();
  const gasPrice = await web3.eth.getGasPrice();
  return gasPrice;
}

export async function getTokenSupply() {
  const web3 = getWeb3();
  const tokenContract = getTokenContract();
  try {
    const result = await tokenContract.methods.totalSupply().call();
    return web3.utils.fromWei(result);
  } catch (e) {
    console.error('getTokenSupply -> e', e);
  }
  return 0;
}

export async function getOwnerSupply() {
  const web3 = getWeb3();
  const tokenContract = getTokenContract();
  try {
    const result = await tokenContract.methods.balanceOf(TOKEN_OWNER).call();
    return web3.utils.fromWei(result);
  } catch (e) {
    console.error('getOwnerSupply -> e', e);
  }
  return 0;
}

export async function getUserTokenBalance(account) {
  try {
    const web3 = getWeb3();
    const contractIntance = await getTokenContract();
    const result = await contractIntance.methods.balanceOf(account).call();
    return web3.utils.fromWei(result);
  } catch (error) {
    console.error('getTokenUserbalance -> error', error);
    return 0;
  }
}

export const requiredNetwork = getNetworkById(REQUIRED_NETWORK);

export const chooseRequiredNetwork = () => changeNetwork(REQUIRED_NETWORK);

export const changeNetwork = async (networkId) => {
  try {
    const web3 = getWeb3();
    const networkHex = web3.utils.toHex(networkId);
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: networkHex }],
    });
    return true;
  } catch (error) {
    console.error('getWalletAccount -> error', error);
  }
  return false;
};

export const getNetwork = async () => {
  try {
    const web3 = getWeb3();
    const networkId = await web3.eth.net.getId();
    if (!networkId) return null;
    const parsedNetworkId = parseInt(networkId, 10);
    const isCorrectNetwork = Number(parsedNetworkId) === Number(REQUIRED_NETWORK);
    const network = getNetworkById(parsedNetworkId);
    return { ...network, isCorrectNetwork };
  } catch (error) {
    console.error('Could not get network ID: ', error);
  }
  return null;
};

export const setupEventHandlers = ({ onNetworkChanged, onAccountsChanged }) => {
  try {
    window.ethereum.on('chainChanged', onNetworkChanged);
    window.ethereum.on('accountsChanged', onAccountsChanged);
  } catch (error) {
    console.error('setupEventHandlers -> error', error);
  }
};

export const isConnected = () => Boolean(window.ethereum && window.ethereum.selectedAddress);

export const getUserAccount = async () => {
  try {
    const web3 = getWeb3();
    const accounts = await web3.eth.getAccounts();
    return accounts && accounts[0] ? accounts[0] : null;
  } catch (error) {
    console.error('Could not get user accounts: ', error);
  }
  return null;
};

export const userConnect = async () => {
  try {
    const address = await window.ethereum.request({ method: 'eth_requestAccounts' });
    return address ? address[0] : '';
  } catch (error) {
    console.error('getWalletAccount -> error', error);
  }
  return null;
};

export const claim = async (type, callback) => {
  try {
    const vestingContract = await getVestingContractByType(type);
    const userAccount = await getUserAccount();
    const gasPrice = await getGasPrice();
    await vestingContract.methods.claim()
      .send({ from: userAccount, gasPrice })
      .on('transactionHash', (hash) => {
        callback({ status: 'pending', transactionHash: hash });
      })
      .on('confirmation', (confirmationNumber, receipt) => {
        if (!receipt.status) {
          callback({ status: 'error' });
          return;
        }

        if (confirmationNumber === 0) {
          callback({
            status: 'success',
            transactionHash: receipt.transactionHash,
          });
        }
      })
      .on('error', (error, receipt) => {
        console.log('Receipt', receipt);
        console.error(error);
      });
  } catch (error) {
    console.error('claim -> error', error);
  }
};

export const getVestingSchedules = async () => {
  try {
    const [
      vestingContractSeed,
      vestingContractPrivate,
      vestingContractEcosystem,
      vestingContractCoreTeam,
      vestingContractOperations,
    ] = await Promise.all([
      getVestingContractByType('seed'),
      getVestingContractByType('private'),
      getVestingContractByType('ecosystem_referrals'),
      getVestingContractByType('core_team'),
      getVestingContractByType('operations'),
    ]);
    const userAccount = await getUserAccount();
    const [
      vestingInfoSeed,
      vestingInfoPrivate,
      vestingInfoEcosystem,
      vestingInfoCoreTeam,
      vestingInfoOperations,
    ] = await Promise.all([
      vestingContractSeed.methods.vestingAsOf(0).call({ from: userAccount }),
      vestingContractPrivate.methods.vestingAsOf(0).call({ from: userAccount }),
      vestingContractEcosystem.methods.vestingAsOf(0).call({ from: userAccount }),
      vestingContractCoreTeam.methods.vestingAsOf(0).call({ from: userAccount }),
      vestingContractOperations.methods.vestingAsOf(0).call({ from: userAccount }),
    ]);
    console.log('🚀 ~ file: web3.js ~ line 232 ~ getVestingSchedules ~ vestingInfoCoreTeam', vestingInfoCoreTeam);
    console.log('🚀 ~ file: web3.js ~ line 232 ~ getVestingSchedules ~ vestingInfoEcosystem', vestingInfoEcosystem);
    console.log('🚀 ~ file: web3.js ~ line 232 ~ getVestingSchedules ~ vestingInfoPrivate', vestingInfoPrivate);
    console.log('🚀 ~ file: web3.js ~ line 232 ~ getVestingSchedules ~ vestingInfoSeed', vestingInfoSeed);
    console.log('🚀 ~ file: web3.js ~ line 232 ~ getVestingSchedules ~ vestingInfoOperations', vestingInfoOperations);

    return [
      (vestingInfoSeed.isValid ? { ...vestingInfoSeed, type: 'seed' } : null),
      (vestingInfoPrivate.isValid ? { ...vestingInfoPrivate, type: 'private' } : null),
      (vestingInfoEcosystem.isValid ? { ...vestingInfoEcosystem, type: 'ecosystem_referrals' } : null),
      (vestingInfoCoreTeam.isValid ? { ...vestingInfoCoreTeam, type: 'core_team' } : null),
      (vestingInfoOperations.isValid ? { ...vestingInfoOperations, type: 'operations' } : null),
    ];
  } catch (error) {
    console.error('getVesting -> error', error);
  }
  return [];
};

export const getClaimHistory = async (type) => {
  const newType = type;
  console.log('CDC ~ file: web3.js ~ line 212 ~ getClaimHistory ~ newType', newType);
  // try {
  //   const vestingContract = await (type === 'seed' ? getVestingContractSeed() : getVestingContractPrivate());
  //   const userAccount = await getUserAccount();
  //   const events = await vestingContract.getPastEvents('TokensClaimed', {
  //     filter: { beneficiary: userAccount },
  //     fromBlock: 24575111,
  //     toBlock: 'latest',
  //   });
  //   return events;
  // } catch (error) {
  //   console.error('getClaimHistory -> error', error);
  // }
  return null;
};
