/* eslint-disable import/prefer-default-export */
import { zonedTimeToUtc } from 'date-fns-tz';
import isAfter from 'date-fns/isAfter';
import differenceInDays from 'date-fns/differenceInDays';
import differenceInHours from 'date-fns/differenceInHours';
import differenceInMonths from 'date-fns/differenceInMonths';

const SECONDS_PER_DAY = 24 * 60 * 60;
const SEED_START = process.env.REACT_APP_SEED_START;
const PRIVATE_START = process.env.REACT_APP_PRIVATE_START;
const DEV_START = process.env.REACT_APP_DEV_START;
const CORE_START = process.env.REACT_APP_CORE_START;

const startDates = {
  seed: SEED_START,
  private: PRIVATE_START,
  ecosystem_referrals: DEV_START,
  core_team: CORE_START,
  operations: CORE_START,
};

const zeroPad = (n) => (n < 10 ? `0${n}` : n);

const getNextUnlockTime = (type) => {
  const now = new Date();
  const startDate = zonedTimeToUtc(new Date(startDates[type]), 'Europe/Paris');

  if (isAfter(now, startDate) || differenceInDays(startDate, now) <= 0) {
    const tomorrow = zonedTimeToUtc(new Date(), 'Europe/Paris');
    tomorrow.setHours(0);
    tomorrow.setMinutes(0);
    tomorrow.setSeconds(0);
    tomorrow.setDate(tomorrow.getDate() + 1);

    const hoursDiff = differenceInHours(tomorrow, now);
    const minutesDiff = 59 - now.getMinutes();
    const secondsDiff = 59 - now.getSeconds();

    return `${zeroPad(hoursDiff)}:${zeroPad(minutesDiff)}:${zeroPad(secondsDiff)}`;
  }

  return `${differenceInDays(startDate, now)} days`;
};

const getCurrentVestingMonthNumber = (totalMonths, date) => {
  if (totalMonths === 1) return 1;

  const now = new Date();
  const vestingDate = new Date(date);
  const diffMonths = differenceInMonths(now, vestingDate);
  return Math.min(totalMonths, Math.max(0, diffMonths));
};

const getVestingCompletionPercentage = (totalMonths, date) => {
  const vestingMonth = getCurrentVestingMonthNumber(totalMonths, date);
  return Math.round((vestingMonth / totalMonths) * 100);
};

const getDateFromDay = (dayNumber) => {
  const timestamp = Number(dayNumber) * SECONDS_PER_DAY * 1000;
  return new Date(timestamp);
};

export {
  getDateFromDay,
  getNextUnlockTime,
  getCurrentVestingMonthNumber,
  getVestingCompletionPercentage,
};
