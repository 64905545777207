import {
  TableFullWidth,
  TableHeader,
  TableHeaderCellBig,
  TableHeaderCellSmall,
  TableBodyRow,
  TableSmallBodyLabelCell,
} from '../Tables';
import { ResponsiveContent } from '../ui';

const NoVesting = ({ userAccount }) => (
  <>
    <ResponsiveContent mobile width="100%">
      <TableFullWidth>
        <TableHeader>
          <tr>
            <TableHeaderCellSmall isFirst isLast>No Vesting Found for the address:</TableHeaderCellSmall>
          </tr>
          <tr>
            <TableHeaderCellBig textAlign="center">
              {userAccount.slice(0, 6)}
              ...
              {userAccount.slice(-4)}
            </TableHeaderCellBig>
          </tr>
        </TableHeader>
        <tbody>
          <TableBodyRow isEven>
            <TableSmallBodyLabelCell colSpan={2} textAlign="center">
              Please check your network and connected accounts
            </TableSmallBodyLabelCell>
          </TableBodyRow>
        </tbody>
      </TableFullWidth>
    </ResponsiveContent>
    <ResponsiveContent laptop tablet>
      <TableFullWidth>
        <TableHeader>
          <tr>
            <TableHeaderCellSmall alignLeft isFirst>No Vesting Found for the address:</TableHeaderCellSmall>
            <TableHeaderCellBig isLast>
              {userAccount.slice(0, 6)}
              ...
              {userAccount.slice(-4)}
            </TableHeaderCellBig>
          </tr>
        </TableHeader>
        <tbody>
          <TableBodyRow isEven>
            <TableSmallBodyLabelCell colSpan={2} textAlign="center">
              Please check your network and connected accounts
            </TableSmallBodyLabelCell>
          </TableBodyRow>
        </tbody>
      </TableFullWidth>
    </ResponsiveContent>
  </>
);

export default NoVesting;
