import { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';

import {
  TableFullWidth,
  TableHeader,
  TableHeaderCellBig,
  TableHeaderCellSmall,
  TableBodyRow,
  TableSmallBodyLabelCell,
  TableSmallBodyValueCell,
} from '../Tables';
import { Kasta } from '../icons';
import {
  ResponsiveContent, HSeparator, Flex, VSeparator,
} from '../ui';
import ModalContext from '../../context/modal/ModalContext';
import UserContext from '../../context/user/UserContext';
import { toReadable } from '../../services/decimals';
import { PrimaryLarge } from '../buttons';
import { getNextUnlockTime } from '../../services/vestingDates';
import { claim } from '../../services/web3';
import Analytics from '../../services/analytics';

const CellAligment = styled(Flex)`
  font-family: ${({ theme }) => theme.fonts.primaryBold};;
`;

const KastaIconContainer = styled.div`
  margin-top: 2px;
`;

let unlockTimerHandler = null;

const VestingInfo = ({ vestingInfo, onRefreshVestingInfo }) => {
  const [unlockTime, setUnlockTime] = useState('');
  const {
    setModalData, setModalType, setShowModal, // resetModal,
  } = useContext(ModalContext);

  const userContextData = useContext(UserContext);

  useEffect(() => {
    cancelInterval();
    if (!vestingInfo) return null;

    unlockTimerHandler = setInterval(() => {
      const unlockTimeString = getNextUnlockTime(vestingInfo.type);
      setUnlockTime(unlockTimeString);
      userContextData.setNextUnlockIn(unlockTimeString);
    }, 1000);

    return cancelInterval;
  }, [vestingInfo?.type]);

  const cancelInterval = () => unlockTimerHandler && clearInterval(unlockTimerHandler);

  const handleClaim = async () => {
    Analytics.initClaimTokens(userContextData);
    if (vestingInfo.type === 'private') {
      setModalType('error');
      setShowModal(true);
      return;
    }
    await claim(vestingInfo.type, ({ status, transactionHash }) => {
      Analytics.claimTokensSent({ ...userContextData, transactionId: transactionHash });
      setModalData({
        status,
        transactionHash,
        amount: toReadable(vestingInfo.amountVested - vestingInfo.claimed),
      });
      setModalType('transaction');
      setShowModal(true);

      if (status === 'success') {
        Analytics.claimTokensSuccess({ ...userContextData, transactionId: transactionHash });
      }
      onRefreshVestingInfo();
    });
  };

  const disableClaimButton = vestingInfo.amountVested - vestingInfo.claimed <= 0;

  return (
    <>
      <ResponsiveContent mobile width="100%">
        <TableFullWidth>
          <TableHeader>
            <tr>
              <TableHeaderCellSmall isFirst isLast>TOTAL AMOUNT</TableHeaderCellSmall>
            </tr>
            <tr>
              <TableHeaderCellBig textAlign="center">
                {toReadable(vestingInfo.totalAmount)}
              </TableHeaderCellBig>
            </tr>
          </TableHeader>
          <tbody>
            <TableBodyRow isEven>
              <TableSmallBodyLabelCell textAlign="center">NEXT UNLOCK IN:</TableSmallBodyLabelCell>
            </TableBodyRow>
            <TableBodyRow isEven>
              <TableSmallBodyValueCell textAlign="center">{unlockTime}</TableSmallBodyValueCell>
            </TableBodyRow>
            <TableBodyRow>
              <TableSmallBodyLabelCell textAlign="center">TOKENS PENDING:</TableSmallBodyLabelCell>
            </TableBodyRow>
            <TableBodyRow>
              <TableSmallBodyValueCell textAlign="center">
                {toReadable(vestingInfo.amountNotVested)}
              </TableSmallBodyValueCell>
            </TableBodyRow>
            <TableBodyRow isEven>
              <TableSmallBodyLabelCell textAlign="center">TOKENS CLAIMED:</TableSmallBodyLabelCell>
            </TableBodyRow>
            <TableBodyRow isEven>
              <TableSmallBodyValueCell textAlign="center">
                {toReadable(vestingInfo.claimed)}
              </TableSmallBodyValueCell>
            </TableBodyRow>
            <TableBodyRow>
              <TableSmallBodyLabelCell textAlign="center">AVAILABLE TO CLAIM:</TableSmallBodyLabelCell>
            </TableBodyRow>
            <TableBodyRow>
              <TableSmallBodyValueCell isFirst isLast textAlign="center">
                <CellAligment align="center" justify="center">
                  {toReadable(vestingInfo.amountVested - vestingInfo.claimed)}
                  {' '}
                  KASTA
                  <VSeparator width="5px" />
                  <KastaIconContainer>
                    <Kasta />
                  </KastaIconContainer>
                </CellAligment>
              </TableSmallBodyValueCell>
            </TableBodyRow>
          </tbody>
        </TableFullWidth>
      </ResponsiveContent>
      <ResponsiveContent laptop tablet>
        <TableFullWidth>
          <TableHeader>
            <tr>
              <TableHeaderCellSmall alignLeft isFirst>TOTAL AMOUNT</TableHeaderCellSmall>
              <TableHeaderCellBig isLast>{toReadable(vestingInfo.totalAmount)}</TableHeaderCellBig>
            </tr>
          </TableHeader>
          <tbody>
            <TableBodyRow isEven>
              <TableSmallBodyLabelCell>NEXT UNLOCK IN:</TableSmallBodyLabelCell>
              <TableSmallBodyValueCell>{unlockTime}</TableSmallBodyValueCell>
            </TableBodyRow>
            <TableBodyRow>
              <TableSmallBodyLabelCell>TOKENS PENDING:</TableSmallBodyLabelCell>
              <TableSmallBodyValueCell>{toReadable(vestingInfo.amountNotVested)}</TableSmallBodyValueCell>
            </TableBodyRow>
            <TableBodyRow isEven>
              <TableSmallBodyLabelCell>TOKENS CLAIMED:</TableSmallBodyLabelCell>
              <TableSmallBodyValueCell>{toReadable(vestingInfo.claimed)}</TableSmallBodyValueCell>
            </TableBodyRow>
            <TableBodyRow>
              <TableSmallBodyLabelCell isLast>AVAILABLE TO CLAIM:</TableSmallBodyLabelCell>
              <TableSmallBodyValueCell isLast>
                <CellAligment align="center" justify="flex-end">
                  {toReadable(vestingInfo.amountVested - vestingInfo.claimed)}
                  {' '}
                  KASTA
                  <VSeparator width="5px" />
                  <KastaIconContainer>
                    <Kasta />
                  </KastaIconContainer>
                </CellAligment>
              </TableSmallBodyValueCell>
            </TableBodyRow>
          </tbody>
        </TableFullWidth>
      </ResponsiveContent>
      <HSeparator />
      <Flex justify="center">
        <PrimaryLarge
          disabled={disableClaimButton}
          onClick={handleClaim}
        >
          Claim KASTA
        </PrimaryLarge>
      </Flex>
    </>
  );
};

export default VestingInfo;
