import { useContext } from 'react';
import styled from 'styled-components';
import ClipLoader from 'react-spinners/ClipLoader';

import UserContext from '../../context/user/UserContext';
import { PrimaryLarge, TransparentLarge } from '../buttons';
import { CheckCircle, Metamask, Link } from '../icons';
import { Flex, HSeparator } from '../ui';
import Analytics from '../../services/analytics';

import { ModalTitle } from './styles';

const BLOCK_EXPLORER_URL = process.env.REACT_APP_BLOCK_EXPLORER_URL;

const CopyButton = styled.a`
  margin-left: 4px;
  cursor: pointer;
  text-decoration: none;
`;

const ButtonText = styled.span`
  margin-left: 8px;
`;

const ModalPill = styled(Flex)`
  width: 100%;
  padding: 10px 16px;
  border-radius: 12px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme }) => theme.colors.greyBg};
`;

const ModalTransaction = ({ data, resetModal }) => {
  const { transactionHash, amount, status } = data;

  const userContextData = useContext(UserContext);

  const handleClosePopup = () => {
    resetModal();
    Analytics.closePopup({ popup: 'transaction', ...userContextData });
  };

  const handleAddKastaClick = () => {
    window.open(
      'https://medium.com/@kasta_app/guide-to-buy-kasta-token-on-uniswap-and-quickswap-1fba8a6259ed',
      '_blank',
    );
    Analytics.addKastaToMetamask(userContextData);
  };

  const modalTitle = status === 'pending'
    ? 'Kasta being claimed'
    : 'Kasta claimed';

  return (
    <Flex direction="column" justify="flex-start">
      {status === 'pending'
        ? <ClipLoader loading color="#631FFE" size={70} />
        : <CheckCircle />}
      <HSeparator />
      <ModalTitle>
        {amount}
        {' '}
        {modalTitle}
      </ModalTitle>
      <HSeparator />
      <ModalPill>
        <span>View on block explorer</span>
        <CopyButton
          href={`${BLOCK_EXPLORER_URL}/tx/${transactionHash}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          <Link href={`${BLOCK_EXPLORER_URL}/tx/${transactionHash}`} />
        </CopyButton>
      </ModalPill>
      <HSeparator height="24px" />
      <TransparentLarge size="small" width="100%" onClick={handleAddKastaClick}>
        <Flex justify="center">
          <Metamask />
          <ButtonText>Add Kasta to Metamask</ButtonText>
        </Flex>
      </TransparentLarge>
      <HSeparator height="10px" />
      <PrimaryLarge
        size="medium"
        width="100%"
        onClick={handleClosePopup}
      >
        Close
      </PrimaryLarge>
    </Flex>
  );
};

export default ModalTransaction;
