import {
  useState, useEffect, useContext, Fragment,
} from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';

import UserContext from '../../context/user/UserContext';
import { H1 } from '../texts';
import { HSeparator } from '../ui';
import ClaimListItem from './ClaimListItem';
import { getClaimHistory } from '../../services/web3';
import { getDateFromDay } from '../../services/vestingDates';
import { device } from '../../styles/responsiveness';

const ClaimsList = styled.div`
  padding: 32px 24px 24px;
  border-radius: 14px;
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.greyDivider};

  
  @media ${device.laptop} {
    max-width: 1032px;
  }
`;

const ClaimListDate = styled.p`
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.colors.textOnBgDark};
  `;

const ClaimHistory = () => {
  const [events, setEvents] = useState([]);
  const userContextData = useContext(UserContext);

  useEffect(() => {
    const fetchEvents = async () => {
      const claimEvents = await getClaimHistory();
      console.log('IMTCHLG ~ file: ClaimHistory.js ~ line 11 ~ fetchEvents ~ claimEvents', claimEvents);
      setEvents(claimEvents);
    };
    fetchEvents();
  }, [userContextData?.account, userContextData?.network?.id]);

  if (!events || events.length === 0) return null;

  return (
    <>
      <H1 align={['left', 'center', 'left']}>
        Claim
        {' '}
        <span className="withStroke">History</span>
      </H1>
      <HSeparator />
      <ClaimsList>
        {events.map((event, eventIndex) => {
          const { transactionHash, returnValues } = event;
          const { onDay, amount } = returnValues;
          const eventDate = getDateFromDay(onDay);

          const lastEvent = eventIndex > 0
            ? events[eventIndex - 1]
            : null;

          const lastEventDate = eventIndex > 0
            ? getDateFromDay(events[eventIndex - 1].returnValues.onDay)
            : null;

          const differentDate = lastEvent
            && format(eventDate, 'MMMM yyyy') !== format(lastEventDate, 'MMMM yyyy');

          return (
            <Fragment key={transactionHash}>
              {eventIndex === 0 && (
                <>
                  <ClaimListDate>{format(eventDate, 'MMMM yyyy')}</ClaimListDate>
                  <HSeparator />
                </>
              )}
              {differentDate && (
                <>
                  <ClaimListDate>{format(eventDate, 'MMMM yyyy')}</ClaimListDate>
                  <HSeparator />
                </>
              )}
              <ClaimListItem amount={amount} date={eventDate} />
              <HSeparator height="8px" />
            </Fragment>
          );
        })}
      </ClaimsList>
    </>
  );
};

export default ClaimHistory;
