import styled from 'styled-components';

import logo from '../assets/img/logo-white.png';
import { device } from '../styles/responsiveness';
import {
  Flex, Box, HSeparator, ResponsiveContent,
} from './ui';
import {
  Instagram, Medium, Telegram, Twitter, YouTube,
} from './icons';

const FooterWrapper = styled.footer`
  background-color: ${({ theme }) => theme.colors.textOnBg};
  padding: 40px 0 16px 16px;
  @media ${device.tablet} {
    padding: 40px 0 26px 0;
  }
`;

const FooterContent = styled.div`
  max-width: 600px;
  margin: auto;
  color: ${({ theme }) => theme.colors.white};

  @media ${device.tablet} {
    max-width: 672px;
  }
  @media ${device.laptop} {
    max-width: 1032px;
  }
`;

const FooterLogoContainer = styled(Flex)`
  justify-content: flex-start;

  @media ${device.tablet} {
    justify-content: center;
  }
  @media ${device.laptop} {
    justify-content: center;
  }
`;

const FooterLogo = styled.img`
  height: 34px;
  
  @media ${device.tablet} {
    height: 36px;
  }
`;

const FooterLinksContainer = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
  padding: 36px 0 28px 32px;
  
  @media ${device.tablet} {
    padding: 36px 0 40px 0;
    flex-direction: row;
    justify-content: center;
  }
`;

const FooterLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.white};
  margin: 15px 0;
  font-size: 16px;
  letter-spacing: 1.25px;
  font-weight: 700;
  
  @media ${device.tablet} {
    margin: 0 32px;
  }
`;

const FooterLinkSmall = styled(FooterLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.white};
  margin: 0;
  &:first-child {
    margin-left: 0;
  }

  @media ${device.tablet} {
    margin: 0 16px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  opacity: 0.2;
  background-color: ${({ theme }) => theme.colors.greyDivider};
`;

const FooterBottomContainer = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
  padding: 48px 0 26px 0;
  
  @media ${device.tablet} {
    padding: 34px 0 26px 0;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Copyright = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-size: 16px;
  line-height: 28px;
  letter-spacing:0.5px;
`;

const SocialLinksContainer = styled(Flex)`
  width: 246px;
  order: -1;
  margin-bottom: 28px;

  @media ${device.tablet} {
    order: 1;
    margin: 0;
  }
`;

const Footer = () => (
  <FooterWrapper>
    <FooterContent>
      <FooterLogoContainer>
        <FooterLogo src={logo} />
      </FooterLogoContainer>
      <ResponsiveContent laptop tablet>
        <HSeparator />
      </ResponsiveContent>
      <ResponsiveContent mobile>
        <HSeparator height="6px" />
      </ResponsiveContent>
      <FooterLinksContainer>
        <FooterLink href="https://www.kasta.io/">Home</FooterLink>
        <FooterLink href="https://www.kasta.io/kasta-token/">Our token</FooterLink>
        <FooterLink href="https://www.kasta.io/company/">Company</FooterLink>
        <FooterLink href="https://www.kasta.io/privacy-policy/">Privacy Policy</FooterLink>
      </FooterLinksContainer>
      <ResponsiveContent laptop tablet>
        <HSeparator height="4px" />
      </ResponsiveContent>
      <Divider />
      <FooterBottomContainer>
        <Box>
          <Copyright>&copy; 2022 Kasta. All rights reserved</Copyright>
        </Box>
        <SocialLinksContainer>
          <FooterLinkSmall href="https://twitter.com/kasta_app" rel="noopener noreferrer" target="_blank">
            <Twitter />
          </FooterLinkSmall>
          <FooterLinkSmall href="https://t.me/kasta_app" rel="noopener noreferrer" target="_blank">
            <Telegram />
          </FooterLinkSmall>
          <FooterLinkSmall href="https://medium.com/@kasta_app" rel="noopener noreferrer" target="_blank">
            <Medium />
          </FooterLinkSmall>
          <FooterLinkSmall
            href="https://www.youtube.com/channel/UCJHVGV7RzYLSzqckFcGq-jQ/videos"
            rel="noopener noreferrer"
            target="_blank"
          >
            <YouTube />
          </FooterLinkSmall>
          <FooterLinkSmall
            href="https://www.instagram.com/app.kasta/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <Instagram />
          </FooterLinkSmall>
        </SocialLinksContainer>
      </FooterBottomContainer>
    </FooterContent>
  </FooterWrapper>
);

export default Footer;
