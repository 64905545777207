import {
  useState, useEffect, useCallback, useContext,
} from 'react';
import styled from 'styled-components';

import UserContext from '../../context/user/UserContext';
import { device } from '../../styles/responsiveness';
import { H1, NetworkPill } from '../texts';
import { getVestingSchedules } from '../../services/web3';
import Analytics from '../../services/analytics';
import {
  HSeparator, VSeparator, Flex, Box,
} from '../ui';
import { TransparentSmallButton } from '../buttons';

import NoVesting from './NoVesting';
import VestingInfo from './VestingInfo';
import VestingTypeTabs from './VestingTypeTabs';

const Wrapper = styled.div`
  width: 98%;
  margin: 0;
  text-align: center;

  @media ${device.tablet} {
    margin: 0;
  }
  @media ${device.laptop} {
    margin: auto;
  }
`;

const TableWrapper = styled(Wrapper)`
  width: 100%;
  max-width: 855px;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 24px;
  border-radius: 12px;
  box-shadow: ${({ theme }) => theme.shadows.normal};
`;

const UserAccountInfo = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: stretch;

  @media ${device.tablet} {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  @media ${device.laptop} {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

const UserAccountInfoAddress = styled(Flex)`
  border-radius: 24px;
  border: 1px solid ${({ theme }) => theme.colors.greyDivider};
  color: ${({ theme }) => theme.colors.textOnBgMed};
  padding: 8px 16px;

  @media ${device.tablet} {
    max-width: 45%;
  }
  @media ${device.laptop} {
    max-width: 100%;
  }
`;

const WalletAddressEllipsis = styled.span`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const AccountConnectedIndicator = styled.div`
  width: 16px;
  height: 16px;
  flex: 0 0 16px;
  border-radius: 100%;
  margin-left: 10px;
  background-color: ${({ theme }) => theme.colors.secondary};
`;

const DisconnectButtonWrapper = styled(Box)`
  @media ${device.tablet} {
    flex: 0;
  }
`;

const ClaimTable = ({ userAccount, onWalletDisconnect }) => {
  const [vestingInfo, setVestingInfo] = useState({
    seed: null,
    private: null,
    ecosystem_referrals: null,
    core_team: null,
    operations: null,
  });
  const userContextData = useContext(UserContext);

  const fetchVestingInfo = useCallback(async () => {
    const [seedInfo, privateInfo, ecosystemInfo, coreTeamInfo, operationsInfo] = await getVestingSchedules();
    setVestingInfo({
      seed: seedInfo,
      private: privateInfo,
      ecosystem_referrals: ecosystemInfo,
      core_team: coreTeamInfo,
      operations: operationsInfo,
    });
    const info = seedInfo || privateInfo || ecosystemInfo || coreTeamInfo || operationsInfo;

    if (info) {
      Analytics.validWalletSuccess({ ...userContextData, vestingInfo: info });
      userContextData.setVestingInfo(info);
    } else {
      Analytics.errorMessage({
        message: 'Vesting schedule not found',
        ...userContextData,
      });
    }
  }, [setVestingInfo]);

  useEffect(() => {
    fetchVestingInfo();
  }, [userAccount, userContextData.network.id]);

  const handleVestinTypeChange = (newType) => {
    const info = vestingInfo[newType];
    Analytics.validWalletSuccess({ ...userContextData, vestingInfo: info });
    userContextData.setVestingInfo(info);
  };

  const currentVestingType = userContextData?.vestingInfo?.type;
  const currentVestingInfo = currentVestingType ? vestingInfo[userContextData.vestingInfo.type] : null;

  const userHasMoreThanOneVesting = (vestingInfo.seed ? 1 : 0)
      + (vestingInfo.private ? 1 : 0)
      + (vestingInfo.ecosystem_referrals ? 1 : 0)
      + (vestingInfo.core_team ? 1 : 0)
      + (vestingInfo.operations ? 1 : 0)
    > 1;

  return (
    <>
      <Wrapper>
        <H1 color="white" height="36px" size="32px">
          Kasta
          {' '}
          <span className="withStroke">Token</span>
          {' '}
          Dashboard
        </H1>
      </Wrapper>
      <HSeparator />
      <TableWrapper>
        <HSeparator />
        <UserAccountInfo>
          <UserAccountInfoAddress>
            <WalletAddressEllipsis>{userAccount}</WalletAddressEllipsis>
            <AccountConnectedIndicator />
          </UserAccountInfoAddress>
          <VSeparator width="16px" />
          <Box flex="0">
            <NetworkPill light>{userContextData?.network?.name}</NetworkPill>
          </Box>
          <VSeparator width="16px" />
          <DisconnectButtonWrapper>
            <TransparentSmallButton onClick={onWalletDisconnect}>Disconnect</TransparentSmallButton>
          </DisconnectButtonWrapper>
        </UserAccountInfo>
        <HSeparator />
        {userHasMoreThanOneVesting && (
          <VestingTypeTabs
            currentType={currentVestingType}
            vestingInfo={vestingInfo}
            onVestingTypeChange={handleVestinTypeChange}
          />
        )}
        <HSeparator />
        {currentVestingInfo ? (
          <VestingInfo vestingInfo={currentVestingInfo} onRefreshVestingInfo={fetchVestingInfo} />
        ) : (
          <NoVesting userAccount={userAccount} />
        )}
      </TableWrapper>
    </>
  );
};

export default ClaimTable;
