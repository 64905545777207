import styled from 'styled-components';

import { device, devicesIndexes } from '../../styles/responsiveness';

const getHeightFromProps = (height, deviceMode) => {
  if (!height) return '20px';
  if (!Array.isArray(height)) return height;
  return height[devicesIndexes[deviceMode]];
};

const HSeparator = styled.div`
  height: ${({ height }) => getHeightFromProps(height, 'mobile')};

  @media ${device.tablet} {
    height: ${({ height }) => getHeightFromProps(height, 'tablet')};
  }
  @media ${device.laptop} {
    height: ${({ height }) => getHeightFromProps(height, 'laptop')};
  }
`;

export default HSeparator;
