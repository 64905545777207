import styled from 'styled-components';

const BaseText = styled.p`
  width: 100%;
  text-align: ${({ textAlign }) => (textAlign || 'center')};;
  font-family: ${({ theme }) => theme.fonts.primaryRegular};
  color: ${({ theme }) => theme.colors.textOnBg};
`;

export default BaseText;
