import { TransparentLarge } from '../buttons';
import { Warning } from '../icons';
import { Flex, HSeparator } from '../ui';

import { ModalTitle, ModalText } from './styles';

const ModalError = ({ resetModal }) => {
  const handleClosePopup = () => {
    resetModal();
  };

  return (
    <Flex direction="column" justify="center">
      <Warning />
      <HSeparator height="8px" />
      <ModalTitle>Unexpected Error</ModalTitle>
      <HSeparator height="8px" />
      <ModalText>
        An error has occurred; kindly get in touch with the Kasta team for assistance.
      </ModalText>
      <HSeparator height="24px" />
      <TransparentLarge
        size="medium"
        width="100%"
        onClick={handleClosePopup}
      >
        Close
      </TransparentLarge>
    </Flex>
  );
};

export default ModalError;
