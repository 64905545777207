import styled from 'styled-components';

const SecondaryLargeButton = styled.button`
  padding: 16px 24px;
  font-family: ${({ theme }) => theme.fonts.primaryBold};
  font-size: 16px;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme }) => theme.colors.white};
  border: none;
  border-radius: 12px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary050};
    color: ${({ theme }) => theme.colors.primary400};
  }
  &:focus {
    background-color: ${({ theme }) => theme.colors.greyBg};
    color: ${({ theme }) => theme.colors.primary300};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.primary100};
    cursor: auto;
    pointer-events: none;
  }
`;

export default SecondaryLargeButton;
