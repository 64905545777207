import styled from 'styled-components';

import { device } from '../styles/responsiveness';
import { Flex } from './ui';

export const TableFullWidth = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const TableHeader = styled.thead`
  background-color: ${({ theme }) => theme.colors.primary};;
`;

export const TableHeaderCell = styled.th`
  color: ${({ theme }) => theme.colors.white};
  text-align: ${({ alignLeft }) => (alignLeft ? 'left' : 'center')};
  border-top-left-radius: ${({ isFirst }) => (isFirst ? '10px' : '0')};;
  border-top-right-radius: ${({ isLast }) => (isLast ? '10px' : '0')};;
  font-family: ${({ theme }) => theme.fonts.primaryBold};
  font-size: 12px;
  padding: 24px 8px;
  letter-spacing: 0.1px;

  @media ${device.tablet} {
    font-size: 16px;
    padding: 24px;
  }
  @media ${device.laptop} {
    font-size: 14px;
  }
`;

export const TableHeaderCellBig = styled(TableHeaderCell)`
  font-family: ${({ theme }) => theme.fonts.primaryBold};
  font-size: 24px;
  text-align: ${({ textAlign }) => (textAlign || 'right')};;
  padding-top: 6px;
  letter-spacing: 0.18px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${device.tablet} {
    padding-top: 24px;
  }
`;
export const TableHeaderCellSmall = styled(TableHeaderCell)`
  font-family: ${({ theme }) => theme.fonts.primaryRegular};
  padding-bottom: 6px;
  letter-spacing: 1.25px;

  @media ${device.tablet} {
    padding-bottom: 24px;
  }
`;

export const TableBodyRow = styled.tr`
  background-color: ${({ isEven, theme }) => (isEven ? theme.colors.white : theme.colors.greyDivider)};
  text-align: ${({ textAlign }) => (textAlign || 'left')};;
`;

export const TableCell = styled.td`
  font-size: 12px;
  padding: 24px 8px;
  letter-spacing: 0.4px;

  @media ${device.tablet} {
    font-size: 16px;
    padding: 24px;
  }
  @media ${device.laptop} {
    font-size: 14px;
  }
`;

export const TableBodyAllocCell = styled(TableCell)`
  color: ${({ theme }) => theme.colors.textOnBg};
  border-bottom-left-radius: ${({ isLast }) => (isLast ? '10px' : '0')};;
`;
export const TableBodyPercentCell = styled(TableCell)`
  color: ${({ theme }) => theme.colors.textOnBgLight};
  text-align: center;
`;
export const TableBodyVestingCell = styled(TableCell)`
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  border-bottom-right-radius: ${({ isLast }) => (isLast ? '10px' : '0')};;
`;

export const SmallTablesContainer = styled(Flex)`
  flex-direction: column;
  @media ${device.laptop} {
    flex-direction: row; 
    align-items: flex-start; 
  }

`;

export const TableSmallBodyLabelCell = styled(TableCell)`
font-family: ${({ theme }) => theme.fonts.primaryBold};
  color: ${({ theme }) => theme.colors.primary};
  text-align: ${({ textAlign }) => (textAlign || 'left')};
  border-bottom-left-radius: ${({ isLast }) => (isLast ? '10px' : '0')};;
  font-size: 16px;
  line-height: 16px;
  padding-bottom: 6px;
  letter-spacing: 1.25px;
  @media ${device.tablet} {
    padding-bottom: 24px;
  }
`;

export const TableSmallBodyValueCell = styled(TableCell)`
  font-family: ${({ theme }) => theme.fonts.primaryBold};
  color: ${({ theme }) => theme.colors.primary};
  text-align: ${({ textAlign }) => (textAlign || 'right')};
  border-bottom-right-radius: ${({ isLast }) => (isLast ? '10px' : '0')};;
  border-bottom-left-radius: ${({ isFirst }) => (isFirst ? '10px' : '0')};;
  font-size: 24px;
  line-height: 24px;
  padding-top: 6px;
  letter-spacing: 0.18px;
  @media ${device.tablet} {
    padding-top: 24px;
  }
`;
