import styled from 'styled-components';

import { H1 } from './texts';
import {
  HSeparator, Flex,
} from './ui';
import { SecondaryLarge } from './buttons';
import { Metamask } from './icons';

const HomeHeroText = styled.p`
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.colors.white};
`;

const ButtonText = styled.span`
  margin-left: 8px;
`;

const HomeConnect = ({ isLoading, onWalletConnect }) => (
  <>
    <H1 align="left" color="white">
      Claim Your Kasta
      {' '}
      <span className="withStroke">Token</span>
    </H1>
    <HSeparator height="32px" />
    <HomeHeroText>In order to claim your tokens, please connect your browser wallet</HomeHeroText>
    <HSeparator height="32px" />
    <SecondaryLarge disabled={isLoading} onClick={onWalletConnect}>
      <Flex justify="center">
        <Metamask />
        <ButtonText>Connect Metamask</ButtonText>
      </Flex>
    </SecondaryLarge>
  </>
);

export default HomeConnect;
