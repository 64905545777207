export default {
  colors: {
    primary: '#631FFE',
    primary600: '#5B1BFE',
    primary400: '#7A41FE',
    primary300: '#9262FE',
    primary100: '#D0BCFF',
    primary050: '#ECE4FF',
    secondary: '#01D5BF',
    accent: '#FFE35E',
    textOnBg: '#180840',
    textOnBgLight: '#18084038',
    textOnBgMed: 'rgba(24,8,64,0.68)',
    textOnBgDark: '#18084087',
    greyBg: '#F6F6F6',
    greyDivider: '#EFEFEF',
    white: '#FFFFFF',
  },
  fonts: {
    primaryRegular: 'UCityRegular',
    primaryBold: 'UCityBold',
    secondaryBlack: 'MonumentBlack',
  },
  shadows: {
    normal: `
      0px 24px 38px rgba(0, 0, 0, 0.07),
      0px 9px 46px rgba(0, 0, 0, 0.06),
      0px 11px 15px rgba(0, 0, 0, 0.1)
    `,
    strong: `
      0px 4px 5px rgba(0, 0, 0, 0.07),
      0px 1px 10px rgba(0, 0, 0, 0.06),
      0px 2px 4px rgba(0, 0, 0, 0.1);
    `,
  },
};
