import styled from 'styled-components';

import { device, devicesIndexes } from '../../styles/responsiveness';

const getTextAlignFromProps = (align, deviceMode) => {
  if (!align) return 'center';
  if (!Array.isArray(align)) return align;
  return align[devicesIndexes[deviceMode]];
};

const H1 = styled.h1`
  font-size: ${({ size }) => size || '40px'};
  line-height: ${({ height }) => height || '58px'};
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-family: ${({ theme }) => theme.fonts.secondaryBlack};
  color: ${({ color, theme }) => color || theme.colors.textOnBg};
  text-align: ${({ align }) => getTextAlignFromProps(align, 'mobile')};
  width: 100%;

  .withStroke {
    font-family: ${({ theme }) => theme.fonts.secondaryBlack};
    color: ${({ color, theme }) => color || theme.colors.textOnBg};
    -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: ${({ color, theme }) => color || theme.colors.textOnBg};
  }

  @media ${device.tablet} {
    font-size: 58px;
    line-height: 72px;
    text-align: ${({ align }) => getTextAlignFromProps(align, 'tablet')};
    width: 100%;
  }

  @media ${device.laptop} {
    text-align: ${({ align }) => getTextAlignFromProps(align, 'laptop')};
  }
`;

export default H1;
