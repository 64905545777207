import { toReadableNumber } from './decimals';

const EVENTS = {
  ADD_KASTA_TO_METAMASK: 'add_kasta_to_metamask',
  CHANGE_NETWORK: 'change_network',
  CLAIM_TOKENS: 'claim_tokens',
  CLAIM_TOKENS_SENT: 'transaction_sent',
  CLAIM_TOKENS_SUCCESS: 'purchase',
  CLOSE_POPUP: 'close',
  CONNECT_WALLET: 'connect_wallet',
  CONNECT_WALLET_INITIATED: 'connect_wallet_initiated',
  CONNECT_WALLET_SUCCESS: 'connect_wallet_success',
  DISCONNECT_WALLET: 'disconnect_wallet',
  ERROR_MESSAGE: 'error_message',
  INSPECT_WALLET: 'inspect_wallet',
  SWITCH_NETWORK_SUCCESS: 'switch_network_success',
  VALID_WALLET_SUCCESS: 'valid_wallet_success',
  VIEW_MORE_HISTORY: 'view_more',
};

const getDataLayer = () => window.dataLayer || [];

const reportEvent = (event, data) => {
  const dataLayer = getDataLayer();
  // eslint-disable-next-line fp/no-mutating-methods
  dataLayer.push({ event, ...data });
};

const parseEventData = ({
  account, vestingInfo, walletType, nextUnlockIn,
}) => ({
  ...(walletType && { wallet_type: walletType }),
  ...(account && { wallet_id: account }),
  ...(nextUnlockIn && { next_unlock_in: nextUnlockIn }),
  ...(vestingInfo && { total_tokens_amount: toReadableNumber(vestingInfo.totalAmount) }),
  ...(vestingInfo && { pending_tokens: toReadableNumber(vestingInfo.amountNotVested) }),
  ...(vestingInfo && { tokens_claimed: toReadableNumber(vestingInfo.claimed) }),
  ...(vestingInfo && { vesting_type: vestingInfo.type }),
  ...(vestingInfo && {
    tokens_available_to_claim: toReadableNumber(vestingInfo.amountVested - vestingInfo.claimed),
  }),
});

const initConnectWallet = () => reportEvent(EVENTS.CONNECT_WALLET_INITIATED);

const connectWallet = (eventData) => reportEvent(EVENTS.CONNECT_WALLET, parseEventData(eventData));

const connectWalletSuccess = (eventData) => (
  reportEvent(EVENTS.CONNECT_WALLET_SUCCESS, parseEventData(eventData))
);

const validWalletSuccess = (eventData) => (
  reportEvent(EVENTS.VALID_WALLET_SUCCESS, parseEventData(eventData))
);

const initClaimTokens = (eventData) => (
  reportEvent(EVENTS.CLAIM_TOKENS, parseEventData(eventData))
);
const claimTokensSent = (eventData) => (
  reportEvent(EVENTS.CLAIM_TOKENS_SENT, parseEventData(eventData))
);

const claimTokensSuccess = ({ transactionId, ...eventData }) => {
  const eventDataParsed = parseEventData(eventData);
  reportEvent(
    EVENTS.CLAIM_TOKENS_SUCCESS,
    {
      ...eventDataParsed,
      ecommerce: {
        transaction_id: transactionId,
        value: 0,
        currency: 'USD',
        coupon: eventDataParsed.wallet_id,
        items: [
          {
            item_id: 'Claim Tokens',
            item_name: 'Withdrawal - Kasta',
            price: 0,
            currency: 'USD',
            quantity: eventDataParsed.tokens_available_to_claim,
            item_brand: 'Kasta',
            item_variant: 'Withdrawal',
          },
        ],
      },
    },
  );
};

const disconnectWallet = (eventData) => reportEvent(EVENTS.DISCONNECT_WALLET, parseEventData(eventData));

const addKastaToMetamask = (eventData) => (
  reportEvent(EVENTS.ADD_KASTA_TO_METAMASK, parseEventData(eventData))
);

const closePopup = ({ popup, ...eventData }) => (
  reportEvent(EVENTS.CLOSE_POPUP, { popup_module: popup, ...parseEventData(eventData) })
);

const errorMessage = ({ message, ...eventData }) => (
  reportEvent(EVENTS.ERROR_MESSAGE, { error_message: message, ...parseEventData(eventData) })
);

const changeNetwork = (eventData) => reportEvent(EVENTS.CHANGE_NETWORK, parseEventData(eventData));

const inspectWallet = (eventData) => reportEvent(EVENTS.INSPECT_WALLET, parseEventData(eventData));

const switchNetworkSuccess = (eventData) => (
  reportEvent(EVENTS.SWITCH_NETWORK_SUCCESS, parseEventData(eventData))
);

const viewMoreHistory = (eventData) => reportEvent(EVENTS.VIEW_MORE_HISTORY, parseEventData(eventData));

const AnalyticsService = {
  addKastaToMetamask,
  claimTokensSent,
  claimTokensSuccess,
  closePopup,
  changeNetwork,
  connectWallet,
  connectWalletSuccess,
  disconnectWallet,
  errorMessage,
  initClaimTokens,
  initConnectWallet,
  inspectWallet,
  switchNetworkSuccess,
  validWalletSuccess,
  viewMoreHistory,
};

export default AnalyticsService;
