import styled from 'styled-components';

const TransparentSmallButton = styled.button`
  width: 100%;
  padding: 10px 24px;
  font-family: ${({ theme }) => theme.fonts.primaryBold};
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 12px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    border-color: ${({ theme }) => theme.colors.primary400};
    color: ${({ theme }) => theme.colors.primary400};
  }
  &:focus {
    color: ${({ theme }) => theme.colors.primary300};
    border-color: ${({ theme }) => theme.colors.primary300};
  }
  &:disabled {
    color: ${({ theme }) => theme.colors.primary100};
    border-color: ${({ theme }) => theme.colors.primary100};
    cursor: auto;
    pointer-events: none;
  }
`;

export default TransparentSmallButton;
