import styled from 'styled-components';

import { Flex } from '../ui';
import { device } from '../../styles/responsiveness';

import ModalNetwork from './ModalNetwork';
import ModalTransaction from './ModalTransaction';
import ModalError from './ModalError';

const ModalBg = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.textOnBg};
  opacity: 0.68;
`;

const ModalContentWrapper = styled(Flex)`
  position: fixed;
  top: 0;
  left: 0;
  padding-bottom: 10%;
  width: 100vw;
  height: 100vh;
`;

const ModalContent = styled.div`
  width: 90%;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 12px;
  padding: 24px;

  @media ${device.tablet} {
    max-width: 440px;
    padding: 24px 72px;
  }

  @media ${device.laptop} {
    max-width: 328px;
    padding: 24px;
  }
`;

const ModalTypes = {
  network: ModalNetwork,
  transaction: ModalTransaction,
  error: ModalError,
};

const Modal = ({
  modalData, modalType, resetModal, showModal,
}) => {
  if (!showModal) return null;
  const ModalComponent = ModalTypes[modalType];

  return (
    <>
      <ModalBg onClick={resetModal} />
      <ModalContentWrapper align="center" justify="center">
        <ModalContent>
          <ModalComponent data={modalData} resetModal={resetModal} />
        </ModalContent>
      </ModalContentWrapper>
    </>
  );
};

export default Modal;
