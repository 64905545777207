import { createGlobalStyle } from 'styled-components';

import PPMonumentExtendedBlackWoff from './monument/PPMonumentExtended-Black.woff';
import PPMonumentExtendedBlackWoff2 from './monument/PPMonumentExtended-Black.woff2';
import UCityProWebBoldWoff from './ucity/UCityProWeb-Bold.woff';
import UCityProWebBoldWoff2 from './ucity/UCityProWeb-Bold.woff2';
import UCityProWebRegularWoff from './ucity/UCityProWeb-Regular.woff';
import UCityProWebRegularWoff2 from './ucity/UCityProWeb-Regular.woff2';

export default createGlobalStyle`
    @font-face {
        font-family: 'MonumentBlack';
        src: local('MonumentBlack'), local('MonumentBlack'),
        url(${PPMonumentExtendedBlackWoff2}) format('woff2'),
        url(${PPMonumentExtendedBlackWoff}) format('woff');
        font-weight: 900;
        font-style: black;
    }
    @font-face {
        font-family: 'UCityBold';
        src: local('UCityBold'), local('UCityBold'),
        url(${UCityProWebBoldWoff2}) format('woff2'),
        url(${UCityProWebBoldWoff}) format('woff');
        font-weight: 700;
        font-style: bold;
    }
    @font-face {
        font-family: 'UCityRegular';
        src: local('UCityRegular'), local('UCityRegular'),
        url(${UCityProWebRegularWoff2}) format('woff2'),
        url(${UCityProWebRegularWoff}) format('woff');
        font-weight: 700;
        font-style: bold;
    }
    * {
      font-family: 'UCityRegular';
    }
`;
