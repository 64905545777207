import styled from 'styled-components';

const paddingSizes = {
  big: '20px 24px',
  medium: '16px',
};

const PrimaryLargeButton = styled.button`
  width: ${({ width }) => width || null};
  padding: ${({ size = 'big' }) => paddingSizes[size]};
  font-family: ${({ theme }) => theme.fonts.primaryBold};
  font-size: 16px;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.primary};
  border: none;
  border-radius: 12px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary400};
  }
  &:focus {
    background-color: ${({ theme }) => theme.colors.primary300};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.colors.primary100};
    cursor: auto;
    pointer-events: none;
  }
`;

export default PrimaryLargeButton;
