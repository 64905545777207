/* eslint-disable max-len */

const MetamaskIcon = () => (
  <svg fill="none" height="24" viewBox="0 0 25 24" width="25" xmlns="http://www.w3.org/2000/svg">
    <path d="M23.3132 0.900391L13.9375 7.83773L15.681 3.74933L23.3132 0.900391Z" fill="#E17726" stroke="#E17726" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.25" />
    <path d="M1.68652 0.900391L10.9787 7.90248L9.31876 3.74933L1.68652 0.900391Z" fill="#E27625" stroke="#E27625" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.25" />
    <path d="M19.937 16.9863L17.4424 20.7973L22.784 22.268L24.3142 17.0696L19.937 16.9863Z" fill="#E27625" stroke="#E27625" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.25" />
    <path d="M0.695312 17.0696L2.21618 22.268L7.54856 20.7973L5.0632 16.9863L0.695312 17.0696Z" fill="#E27625" stroke="#E27625" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.25" />
    <path d="M7.2611 10.5475L5.77734 12.786L11.0633 13.0265L10.8871 7.33789L7.2611 10.5475Z" fill="#E27625" stroke="#E27625" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.25" />
    <path d="M17.7396 10.5479L14.0581 7.27344L13.9375 13.0268L19.2234 12.7863L17.7396 10.5479Z" fill="#E27625" stroke="#E27625" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.25" />
    <path d="M7.54785 20.7961L10.7472 19.2514L7.993 17.1055L7.54785 20.7961Z" fill="#E27625" stroke="#E27625" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.25" />
    <path d="M14.2529 19.2514L17.443 20.7961L17.0072 17.1055L14.2529 19.2514Z" fill="#E27625" stroke="#E27625" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.25" />
    <path d="M17.443 20.7966L14.2529 19.252L14.5126 21.3239L14.4847 22.2026L17.443 20.7966Z" fill="#D5BFB2" stroke="#D5BFB2" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.25" />
    <path d="M7.54785 20.7966L10.5154 22.2026L10.4969 21.3239L10.7472 19.252L7.54785 20.7966Z" fill="#D5BFB2" stroke="#D5BFB2" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.25" />
    <path d="M10.5712 15.7368L7.91895 14.9599L9.79221 14.0996L10.5712 15.7368Z" fill="#233447" stroke="#233447" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.25" />
    <path d="M14.4297 15.7368L15.2087 14.0996L17.0913 14.9599L14.4297 15.7368Z" fill="#233447" stroke="#233447" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.25" />
    <path d="M7.54684 20.7973L8.01055 16.9863L5.06152 17.0696L7.54684 20.7973Z" fill="#CC6228" stroke="#CC6228" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.25" />
    <path d="M16.9883 16.9863L17.4427 20.7973L19.9373 17.0696L16.9883 16.9863Z" fill="#CC6228" stroke="#CC6228" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.25" />
    <path d="M19.2234 12.7861L13.9375 13.0266L14.429 15.7369L15.208 14.0996L17.0905 14.9599L19.2234 12.7861Z" fill="#CC6228" stroke="#CC6228" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.25" />
    <path d="M7.91955 14.9599L9.79282 14.0996L10.5718 15.7369L11.0633 13.0266L5.77734 12.7861L7.91955 14.9599Z" fill="#CC6228" stroke="#CC6228" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.25" />
    <path d="M5.77734 12.7861L7.99376 17.1058L7.91952 14.9599L5.77734 12.7861Z" fill="#E27525" stroke="#E27525" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.25" />
    <path d="M17.0913 14.9599L17.0078 17.1058L19.2242 12.7861L17.0913 14.9599Z" fill="#E27525" stroke="#E27525" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.25" />
    <path d="M11.0638 13.0273L10.5723 15.7376L11.1936 18.938L11.3327 14.7201L11.0638 13.0273Z" fill="#E27525" stroke="#E27525" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.25" />
    <path d="M13.9384 13.0273L13.6787 14.7109L13.8086 18.938L14.4299 15.7376L13.9384 13.0273Z" fill="#E27525" stroke="#E27525" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.25" />
    <path d="M14.4289 15.737L13.8076 18.9374L14.2528 19.2519L17.0071 17.1059L17.0905 14.96L14.4289 15.737Z" fill="#F5841F" stroke="#F5841F" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.25" />
    <path d="M7.91895 14.96L7.99319 17.1059L10.7474 19.2519L11.1926 18.9374L10.5712 15.737L7.91895 14.96Z" fill="#F5841F" stroke="#F5841F" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.25" />
    <path d="M14.4846 22.2029L14.5123 21.3241L14.2713 21.1206H10.7287L10.4969 21.3241L10.5154 22.2029L7.54785 20.7969L8.58649 21.6478L10.6916 23.1001H14.299L16.4134 21.6478L17.4428 20.7969L14.4846 22.2029Z" fill="#C0AC9D" stroke="#C0AC9D" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.25" />
    <path d="M14.2529 19.2519L13.8077 18.9375H11.1926L10.7475 19.2519L10.4971 21.324L10.7289 21.1204H14.2714L14.5126 21.324L14.2529 19.2519Z" fill="#161616" stroke="#161616" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.25" />
    <path d="M23.712 8.29095L24.5003 4.46156L23.3132 0.900391L14.2529 7.60652L17.7398 10.5479L22.6641 11.9817L23.7491 10.7145L23.2762 10.3722L24.0273 9.68773L23.4524 9.24372L24.2035 8.67022L23.712 8.29095Z" fill="#763E1A" stroke="#763E1A" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.25" />
    <path d="M0.5 4.46156L1.29753 8.29095L0.787486 8.67022L1.54792 9.24372L0.972957 9.68773L1.72412 10.3722L1.25116 10.7145L2.33618 11.9817L7.26052 10.5479L10.7474 7.60652L1.68703 0.900391L0.5 4.46156Z" fill="#763E1A" stroke="#763E1A" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.25" />
    <path d="M22.6647 11.9815L17.7404 10.5479L19.2242 12.7863L17.0078 17.1059L19.9383 17.069H24.3154L22.6647 11.9815Z" fill="#F5841F" stroke="#F5841F" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.25" />
    <path d="M7.26102 10.5479L2.33674 11.9815L0.695312 17.069H5.0632L7.99364 17.1059L5.77727 12.7863L7.26102 10.5479Z" fill="#F5841F" stroke="#F5841F" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.25" />
    <path d="M13.9386 13.0276L14.2539 7.60713L15.682 3.75H9.32031L10.7484 7.60713L11.0637 13.0276L11.1843 14.7295L11.1936 18.9382H13.8087L13.818 14.7295L13.9386 13.0276Z" fill="#F5841F" stroke="#F5841F" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.25" />
  </svg>
);

export default MetamaskIcon;
