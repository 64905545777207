import styled from 'styled-components';

const H2 = styled.h2`
  color: ${({ theme }) => theme.colors.textOnBg};
  font-size: 24px;
  font-family: ${({ theme }) => theme.fonts.primaryBold};
  align-self: flex-start;
`;

export default H2;
