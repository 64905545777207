import styled from 'styled-components';
import { format } from 'date-fns';

import {
  Flex, Box, VSeparator,
} from '../ui';
import { KastaNoBg } from '../icons';
import { toReadable } from '../../services/decimals';

const Item = styled(Flex)`
  padding: 12px 16px;
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadows.strong};
`;

const ItemLogo = styled(Flex)`
  padding: 8px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.greyDivider};
`;

const ItemTitle = styled.p`
  font-family: ${({ theme }) => theme.fonts.primaryBold};
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: ${({ theme }) => theme.colors.textOnBgMed};
`;

const ItemDate = styled.p`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme.colors.textOnBgLight};
`;

const ItemAmount = styled.p`
  font-family: ${({ theme }) => theme.fonts.primaryBold};
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: ${({ theme }) => theme.colors.secondary};
  margin-left: auto;
`;

const ClaimListItem = ({ amount, date }) => (
  <Item>
    <ItemLogo
      justify="center"
    >
      <KastaNoBg />
    </ItemLogo>
    <VSeparator width="8px" />
    <Box flex-direction="column">
      <ItemTitle>Claimed</ItemTitle>
      <ItemDate>{format(date, 'dd.MM.yyyy')}</ItemDate>
    </Box>
    <VSeparator width="8px" />
    <ItemAmount>
      +
      {toReadable(amount)}
      {' '}
      KASTA
    </ItemAmount>
  </Item>
);

export default ClaimListItem;
