// import styled from 'styled-components';

// import { device } from '../styles/responsiveness';
// import { getCurrentVestingMonthNumber, getVestingCompletionPercentage } from '../services/vestingDates';

import {
  HSeparator, VSeparator, ResponsiveContent,
} from './ui';
import {
  TableFullWidth,
  TableHeader,
  TableHeaderCellBig,
  TableHeaderCellSmall,
  TableBodyRow,
  SmallTablesContainer,
  TableSmallBodyLabelCell,
  TableSmallBodyValueCell,
} from './Tables';

// const PercentBarContainer = styled.div`
//   width: 100%;
//   height: 8px;
//   border-radius: 4px;
//   background-color: ${({ theme }) => theme.colors.textOnBgLight};

//   @media ${device.tablet} {
//     min-width: 100px;
//   }
//   @media ${device.laptop} {
//   }
// `;
// const PercentBarValue = styled.div`
//   width: ${({ value }) => value}%;
//   height: 8px;
//   border-radius: 4px;
//   background-color: ${({ color }) => color};
// `;

const HomeTables = () => (
  <>
    {/* <TableFullWidth>
      <TableHeader>
        <tr>
          <TableHeaderCell alignLeft isFirst>Allocation</TableHeaderCell>
          <TableHeaderCell alignLeft>Target</TableHeaderCell>
          <TableHeaderCell>%</TableHeaderCell>
          <TableHeaderCell isLast>Vesting</TableHeaderCell>
        </tr>
      </TableHeader>
      <tbody>
        {purposes.map(({
          label, color, vestingInitDate, vestingMonthsDuration,
        }, index) => (
          <TableBodyRow key={label} isEven={index % 2 === 0}>
            <TableBodyAllocCell isLast={index === purposes.length - 1}>{label}</TableBodyAllocCell>
            <TableBodyAllocCell>
              <PercentBarContainer>
                <PercentBarValue
                  color={color}
                  value={getVestingCompletionPercentage(vestingMonthsDuration, vestingInitDate)}
                />
              </PercentBarContainer>
            </TableBodyAllocCell>
            <TableBodyPercentCell>
              {getVestingCompletionPercentage(vestingMonthsDuration, vestingInitDate)}
              %
            </TableBodyPercentCell>
            <TableBodyVestingCell isLast={index === purposes.length - 1}>
              {getCurrentVestingMonthNumber(vestingMonthsDuration, vestingInitDate)}
              /
              {vestingMonthsDuration}
            </TableBodyVestingCell>
          </TableBodyRow>
        ))}

      </tbody>
    </TableFullWidth> */}
    <HSeparator height="80px" />
    <ResponsiveContent laptop tablet width="100%">
      <SmallTablesContainer>
        <TableFullWidth>
          <TableHeader>
            <tr>
              <TableHeaderCellSmall alignLeft isFirst>TOKEN NAME:</TableHeaderCellSmall>
              <TableHeaderCellBig isLast>KASTA</TableHeaderCellBig>
            </tr>
          </TableHeader>
          <tbody>
            <TableBodyRow isEven>
              <TableSmallBodyLabelCell>INITIAL CIRCULATING SUPPLY:</TableSmallBodyLabelCell>
              <TableSmallBodyValueCell>37.5M</TableSmallBodyValueCell>
            </TableBodyRow>
            <TableBodyRow>
              <TableSmallBodyLabelCell>INITIAL MARKET CAP:</TableSmallBodyLabelCell>
              <TableSmallBodyValueCell>$1.5M</TableSmallBodyValueCell>
            </TableBodyRow>
            <TableBodyRow isEven>
              <TableSmallBodyLabelCell isLast>ESTIMATED LISTING:</TableSmallBodyLabelCell>
              <TableSmallBodyValueCell isLast>Q1 2022</TableSmallBodyValueCell>
            </TableBodyRow>
          </tbody>
        </TableFullWidth>
        <ResponsiveContent tablet>
          <HSeparator />
        </ResponsiveContent>
        <ResponsiveContent laptop>
          <VSeparator />
        </ResponsiveContent>
        <TableFullWidth>
          <TableHeader>
            <tr>
              <TableHeaderCellSmall alignLeft isFirst>TOKEN TYPE:</TableHeaderCellSmall>
              <TableHeaderCellBig isLast>ERC20(Polygon)</TableHeaderCellBig>
            </tr>
          </TableHeader>
          <tbody>
            <TableBodyRow isEven>
              <TableSmallBodyLabelCell>TGE TOKEN PRICE:</TableSmallBodyLabelCell>
              <TableSmallBodyValueCell>$0.040</TableSmallBodyValueCell>
            </TableBodyRow>
            <TableBodyRow>
              <TableSmallBodyLabelCell>HARD CAP:</TableSmallBodyLabelCell>
              <TableSmallBodyValueCell>$6.5M</TableSmallBodyValueCell>
            </TableBodyRow>
            <TableBodyRow isEven>
              <TableSmallBodyLabelCell isLast>TOTAL SUPPLY:</TableSmallBodyLabelCell>
              <TableSmallBodyValueCell isLast>1.5 BILLION</TableSmallBodyValueCell>
            </TableBodyRow>
          </tbody>
        </TableFullWidth>
      </SmallTablesContainer>
    </ResponsiveContent>
    <ResponsiveContent mobile width="100%">
      <TableFullWidth>
        <TableHeader>
          <tr>
            <TableHeaderCellSmall isFirst isLast>TOKEN NAME:</TableHeaderCellSmall>
          </tr>
          <tr>
            <TableHeaderCellBig textAlign="center">KASTA</TableHeaderCellBig>
          </tr>
        </TableHeader>
        <tbody>
          <TableBodyRow isEven>
            <TableSmallBodyLabelCell textAlign="center">INITIAL CIRCULATING SUPPLY:</TableSmallBodyLabelCell>
          </TableBodyRow>
          <TableBodyRow isEven>
            <TableSmallBodyValueCell textAlign="center">37.5M</TableSmallBodyValueCell>
          </TableBodyRow>
          <TableBodyRow>
            <TableSmallBodyLabelCell textAlign="center">INITIAL MARKET CAP:</TableSmallBodyLabelCell>
          </TableBodyRow>
          <TableBodyRow>
            <TableSmallBodyValueCell textAlign="center">$1.5M</TableSmallBodyValueCell>
          </TableBodyRow>
          <TableBodyRow isEven>
            <TableSmallBodyLabelCell textAlign="center">ESTIMATED LISTING:</TableSmallBodyLabelCell>
          </TableBodyRow>
          <TableBodyRow isEven>
            <TableSmallBodyValueCell isFirst isLast textAlign="center">Q1 2022</TableSmallBodyValueCell>
          </TableBodyRow>
        </tbody>
      </TableFullWidth>
      <HSeparator />
      <TableFullWidth>
        <TableHeader>
          <tr>
            <TableHeaderCellSmall isFirst isLast>TOKEN TYPE:</TableHeaderCellSmall>
          </tr>
          <tr>
            <TableHeaderCellBig textAlign="center">ERC20(Polygon)</TableHeaderCellBig>
          </tr>
        </TableHeader>
        <tbody>
          <TableBodyRow isEven>
            <TableSmallBodyLabelCell textAlign="center">TGE TOKEN PRICE:</TableSmallBodyLabelCell>
          </TableBodyRow>
          <TableBodyRow isEven>
            <TableSmallBodyValueCell textAlign="center">$0.040</TableSmallBodyValueCell>
          </TableBodyRow>
          <TableBodyRow>
            <TableSmallBodyLabelCell textAlign="center">HARD CAP:</TableSmallBodyLabelCell>
          </TableBodyRow>
          <TableBodyRow>
            <TableSmallBodyValueCell textAlign="center">$6.5M</TableSmallBodyValueCell>
          </TableBodyRow>
          <TableBodyRow isEven>
            <TableSmallBodyLabelCell textAlign="center">TOTAL SUPPLY:</TableSmallBodyLabelCell>
          </TableBodyRow>
          <TableBodyRow isEven>
            <TableSmallBodyValueCell isFirst isLast textAlign="center">1.5 BILLION</TableSmallBodyValueCell>
          </TableBodyRow>
        </tbody>
      </TableFullWidth>
    </ResponsiveContent>
  </>
);

export default HomeTables;
