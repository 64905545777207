/* eslint-disable complexity */
/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import styled from 'styled-components';

import { Box, Flex } from '../ui';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.greyBg};
  border-radius: 24px;
  height: 56px;
`;
const CurrentTabBackgound = styled(Box)`
  position:absolute;
  top: 8px;
  width: calc(${({ tabsNumber }) => 100 / tabsNumber}% - ${({ tabsNumber }) => 8 * (tabsNumber + 1)}px);
  height: 40px;
  transition: left 0.3s;
  border-radius: 24px;
  background-color: ${({ theme }) => theme.colors.white};
  left: calc(8px + ${({ left }) => left}%);
  box-shadow: ${({ theme }) => theme.shadows.strong};
`;

const TabsLabelContainer = styled(Flex)`
  position: absolute;
  width: calc(100% - 16px);
  top: 8px;
  left: 8px;
`;

const TabLabel = styled(Box)`
width: calc(${({ tabsNumber }) => 100 / tabsNumber}% - ${({ tabsNumber }) => 8 * (tabsNumber + 1)}px);
  line-height: 40px;
  transition: color 0.3s;
  font-family: ${({ theme }) => theme.fonts.primaryBold};
  color: ${({ isOn, theme }) => (isOn ? theme.colors.primary : theme.colors.textOnBgMed)};
  cursor: pointer;
`;

const getTabsNumber = (vestingInfo) => ((vestingInfo.seed ? 1 : 0)
+ (vestingInfo.private ? 1 : 0)
+ (vestingInfo.ecosystem_referrals ? 1 : 0)
+ (vestingInfo.core_team ? 1 : 0))
+ (vestingInfo.operations ? 1 : 0);

const getTabsIndex = (vestingInfo) => {
  let index = -1;
  if (vestingInfo.private) index += 1;
  const prvate = index;
  if (vestingInfo.seed) index += 1;
  const seed = index;
  if (vestingInfo.ecosystem_referrals) index += 1;
  const ecosystem_referrals = index;
  if (vestingInfo.core_team) index += 1;
  const core_team = index;
  if (vestingInfo.operations) index += 1;
  const operations = index;

  return {
    // eslint-disable-next-line quote-props
    'private': prvate,
    seed,
    ecosystem_referrals,
    core_team,
    operations,
  };
};

const VestingTypeTabs = ({ currentType, vestingInfo, onVestingTypeChange }) => {
  const tabsIndex = getTabsIndex(vestingInfo);
  const tabsNumber = getTabsNumber(vestingInfo);
  const bgLeft = (100 / tabsNumber) * tabsIndex[currentType];

  return (
    <Wrapper>
      <CurrentTabBackgound currentType={currentType} left={bgLeft} tabsNumber={tabsNumber} />
      <TabsLabelContainer>
        {vestingInfo.private && (
          <TabLabel
            isOn={currentType === 'private'}
            tabsNumber={tabsNumber}
            onClick={() => onVestingTypeChange('private')}
          >
            Private
          </TabLabel>
        )}
        {vestingInfo.seed && (
          <TabLabel
            isOn={currentType === 'seed'}
            tabsNumber={tabsNumber}
            onClick={() => onVestingTypeChange('seed')}
          >
            Seed
          </TabLabel>
        )}
        {vestingInfo.ecosystem_referrals && (
          <TabLabel
            isOn={currentType === 'ecosystem_referrals'}
            tabsNumber={tabsNumber}
            onClick={() => onVestingTypeChange('ecosystem_referrals')}
          >
            Dev
          </TabLabel>
        )}
        {vestingInfo.core_team && (
          <TabLabel
            isOn={currentType === 'core_team'}
            tabsNumber={tabsNumber}
            onClick={() => onVestingTypeChange('core_team')}
          >
            Core Team
          </TabLabel>
        )}
        {vestingInfo.operations && (
          <TabLabel
            isOn={currentType === 'operations'}
            tabsNumber={tabsNumber}
            onClick={() => onVestingTypeChange('operations')}
          >
            Operations
          </TabLabel>
        )}
      </TabsLabelContainer>
    </Wrapper>
  );
};

export default VestingTypeTabs;
