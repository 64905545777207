import { useState } from 'react';

import ModalContext from './ModalContext';
import Modal from '../../components/Modal';

const ModalProvider = ({ children }) => {
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [modalData, setModalData] = useState(null);

  const resetModal = () => {
    setShowModal(false);
    setModalData(null);
    setModalType('');
  };

  return (
    <ModalContext.Provider value={{
      showModal, setShowModal, modalType, setModalType, modalData, setModalData, resetModal,
    }}
    >
      {children}
      <Modal
        modalData={modalData}
        modalType={modalType}
        resetModal={resetModal}
        showModal={showModal}
      />
    </ModalContext.Provider>
  );
};

export default ModalProvider;
