import styled from 'styled-components';

import Header from './Header';
import Footer from './Footer';

const LayoutContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.greyBg};
  min-height: 100vh;
`;

const Layout = ({ children }) => (
  <LayoutContainer>
    <Header />
    {children}
    <Footer />
  </LayoutContainer>
);

export default Layout;
