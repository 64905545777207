/* eslint-disable max-len */
const CheckCircle = () => (
  <svg fill="none" height="72" viewBox="0 0 72 72" width="72" xmlns="http://www.w3.org/2000/svg">
    <path clipRule="evenodd" d="M36 66C52.5685 66 66 52.5685 66 36C66 19.4315 52.5685 6 36 6C19.4315 6 6 19.4315 6 36C6 52.5685 19.4315 66 36 66ZM36 72C55.8822 72 72 55.8822 72 36C72 16.1177 55.8822 0 36 0C16.1177 0 0 16.1177 0 36C0 55.8822 16.1177 72 36 72Z" fill="#631FFE" fillRule="evenodd" />
    <path d="M31.0007 48L21 37.4997L23.8568 34.5003L31.0037 41.9958L31.0007 41.9989L48.1432 24L51 26.9995L33.8574 45.0005L31.0027 47.9979L31.0007 48Z" fill="#631FFE" />
  </svg>

);

export default CheckCircle;
