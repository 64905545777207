import { useState } from 'react';

import UserContext from './UserContext';

const ModalProvider = ({ children }) => {
  const [account, setAccount] = useState('');
  const [network, setNetwork] = useState('');
  const [vestingInfo, setVestingInfo] = useState(null);
  const [walletType, setWalletType] = useState('Metamask');
  const [nextUnlockIn, setNextUnlockIn] = useState('');

  const resetUserInfo = () => {
    setAccount('');
    setVestingInfo(null);
    setNextUnlockIn('');
  };

  return (
    <UserContext.Provider value={{
      account,
      setAccount,
      network,
      setNetwork,
      vestingInfo,
      setVestingInfo,
      walletType,
      setWalletType,
      nextUnlockIn,
      setNextUnlockIn,
      resetUserInfo,
    }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default ModalProvider;
