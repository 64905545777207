import React from 'react';

const UserContext = React.createContext({
  account: '',
  vestingInfo: null,
  walletType: '',
  vestingType: '',
});

export default UserContext;
