import styled from 'styled-components';

import { BaseText } from '../texts';

export const ModalTitle = styled(BaseText)`
  width: 100%;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.18px;
`;

export const ModalText = styled(BaseText)`
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.colors.textOnBgMed};
`;
