/* eslint-disable max-len */
const Kasta = () => (
  <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
    <rect fill="#631FFE" height="24" rx="6" width="24" />
    <path d="M5.39941 15.7643H6.8V13.5031L8.67757 15.7643H10.4721L8.14855 13.1506L10.3894 10.6402H8.62586L6.8 12.8393V8.39941H5.39941V15.7643Z" fill="white" />
    <path clipRule="evenodd" d="M13.1108 15.8994C13.8887 15.8994 14.5211 15.5365 14.8119 14.8414V15.7643H16.0969V13.254C16.0969 11.5839 14.9765 10.5049 13.3703 10.5049C11.7406 10.5049 10.6005 11.6149 10.6005 13.2643C10.6002 15.0282 11.928 15.8994 13.1108 15.8994ZM13.3597 14.5925C12.5608 14.5925 12.0214 14.0218 12.0214 13.2023C12.0214 12.3827 12.5599 11.8124 13.3597 11.8124C14.1594 11.8124 14.6979 12.3827 14.6979 13.2023C14.6979 14.0218 14.1582 14.5925 13.3597 14.5925Z" fill="white" fillRule="evenodd" />
    <path d="M18.561 14.9865C18.561 15.5052 18.1771 15.8994 17.6584 15.8994C17.1294 15.8994 16.7458 15.5048 16.7458 14.9865C16.7458 14.4681 17.1294 14.0739 17.6584 14.0739C18.1771 14.0739 18.561 14.4678 18.561 14.9865Z" fill="white" />
  </svg>
);

export default Kasta;
