/* eslint-disable import/prefer-default-export */
export const ALLOC_PURPOSES = [
  {
    label: 'Seed',
    value: 'seed',
    color: '#180840',
    vestingInitDate: '2022-03-05',
    vestingMonthsDuration: 22,
  },
  {
    label: 'Private',
    value: 'private',
    color: '#631FFE',
    vestingInitDate: '2022-02-05',
    vestingMonthsDuration: 17,
  },
  {
    label: 'TGE',
    value: 'public',
    color: '#7A41FE',
    vestingInitDate: '2022-01-05',
    vestingMonthsDuration: 1,
  },
  {
    label: 'Advisors',
    value: 'advisors',
    color: '#9262FE',
    vestingInitDate: '2022-04-05',
    vestingMonthsDuration: 60,
  },
  {
    label: 'Team',
    value: 'team',
    color: '#B18FFF',
    vestingInitDate: '2022-07-05',
    vestingMonthsDuration: 50,
  },
  {
    label: 'Ecosystem & referrals',
    value: 'ecosystem_referrals',
    color: '#D0BCFF',
    vestingInitDate: '2022-04-05',
    vestingMonthsDuration: 57,
  },
  {
    label: 'Development',
    value: 'dev',
    color: '#ECE4FF',
    vestingInitDate: '2022-02-05',
    vestingMonthsDuration: 59,
  },
  {
    label: 'Marketing',
    value: 'marketing',
    color: '#B3F2EC',
    vestingInitDate: '2022-02-05',
    vestingMonthsDuration: 50,
  },
  {
    label: 'Operations',
    value: 'operations',
    color: '#80EADF',
    vestingInitDate: '2022-01-05',
    vestingMonthsDuration: 24,
  },
  {
    label: 'Exchanges & liquidity',
    value: 'exchange_market',
    color: '#4DE2D2',
    vestingInitDate: '2022-01-05',
    vestingMonthsDuration: 1,
  },
  {
    label: 'Rewards*',
    value: 'rewards',
    color: '#27DBC9',
    vestingInitDate: '2022-05-05',
    vestingMonthsDuration: 59,
  },
  {
    label: 'General reserve',
    value: 'reserve',
    color: '#01D5BF',
    vestingInitDate: '2022-02-05',
    vestingMonthsDuration: 59,
  },
];
